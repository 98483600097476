.reviews-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-bullet-container {
  display: flex;
  width: 20px;
  height: 12px;
  align-items: center;
}

.review-bullet {
  transition: all 1s ease-in-out;
}

.review-bullet-off {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #C4C4C4;
  cursor: pointer;
}

.review-bullet-on {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #F9B46A;
}