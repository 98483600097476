.tasks-container {
  font-family: "Poppins", sans-serif;
  color: #5A5A5A;
}

.tasks-container h1 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 40px;
}

.tasks-cell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 0;
}

.tasks-cell-image-wrapper {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

@media all and (min-width: 992px) {
  .tasks-cells-container > .tasks-cell:not(:first-child):not(:last-child) .tasks-cell-image-wrapper:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 0;
    border: 1px solid #C4C4C4;
    z-index: -1;
    transform: translate(0,-50%);
  }

  .tasks-cells-container > .tasks-cell:first-child .tasks-cell-image-wrapper:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    height: 0;
    border: 1px solid #C4C4C4;
    z-index: -1;
    transform: translate(0,-50%);
  }

  .tasks-cells-container > .tasks-cell:last-child .tasks-cell-image-wrapper:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 50%;
    height: 0;
    border: 1px solid #C4C4C4;
    z-index: -1;
    transform: translate(0,-50%);
  }
}

.tasks-cell-image-wrapper img {
  max-width: 170px;
  max-height: 170px;
  width: 100%;
  height: auto;
}

.tasks-cell h2 {
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
}

.tasks-cell {
  font-size: 14px;
}