.form_section {
  margin-bottom: 30px;
}
  
.form_section label {
  color: #797979;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  display: block;
}

.form_section input[type="text"],
.form_section input[type="email"],
.form_section textarea {
  background-color: #FFFFFF;
  border: 0.5px solid #C9C9C9;
  border-radius: 50px;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
}

.form_section textarea {
  border-radius: 25px;
  height: 120px;
  resize: none;
}

.form_section input[type="text"]:placeholder,
.form_section input[type="email"]:placeholder,
.form_section textarea:placeholder {
  color: #C4C4C4;
  font-size: 16px;
  line-height: 19px;
}

.form_section input[type="text"].error,
.form_section input[type="email"].error,
.form_section textarea.error {
  border: 1px solid #e34652 !important;
}

.form_section .error_text {
  color: #e34652;
  font-size: 16px;
}